.custom-navbar {
    --tw-bg-opacity: 1;
    background-color: #490648;
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
}

.logo {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.link-logo {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.navbar-nav .nav-link {
    margin-right: 20px;
}

.custom-navbar .nav-button {
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 5px;
}

.custom-navbar .login-button {
    margin-right: 10px;
}

.custom-navbar .cart-button {
    background-color: #28a745;
    color: white;
}

.custom-navbar .nav-link {
    color: #fff;
    transition: color 0.3s ease;
    font-weight: 400;
    font-size: 1.1em;
    font-weight: 600
}

.custom-navbar .nav-link:hover {
    color: #ffffffad;
    text-decoration: none;
    font-weight: 600;
    font-size: 1.12em;
}

.ml-auto {
    padding: 0em 2em;
}

.social-link {
    padding: 0.3em 0.4em;
    border-radius: 50%;
    background-color: #fff;
    --tw-bg-opacity: 1;
    color: #490648;
    font-size: 1.25em;
    margin: 0 0.4em;
}

.nav-links-1 {
    margin: 0 1em 0 3.7em;
}

.nav-links-2 {
    margin: 0 3.5em 0 1em;
}

@media (max-width: 900px) {
    .nav-links-1 {
        margin-top: 2em;
        margin-bottom: 2em;
    }
    .nav-links-2 {
        text-align: center;
        margin: 0;
    }
}