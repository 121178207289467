.carousel-container {
    overflow: hidden;
    position: relative;
    height: 100px;
    width: 100%;
}

.carousel-container-1 {
    overflow: hidden;
    position: relative;
    height: 100px;
    width: 100%;
    margin-top: 2em;
}

.carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 150px;
    width: 200%;
    animation: slide 10s linear infinite;
}

.carousel-track-1 {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 100px;
    width: 200%;
    animation: slide1 10s linear infinite;
}

@keyframes slide {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@keyframes slide1 {
    0% {
        left: -100%;
    }
    100% {
        left: 0;
    }
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    /* Adjust width as needed */
    height: 100%;
    background: transparent;
    font-size: 1.4em;
    opacity: 0.4;
}

.carousel-card:hover {
    transform: scale(0.9);
}

.slick-prev:before,
.slick-next:before {
    color: #000000d0 !important;
}

.slick-prev,
.slick-next {
    font-size: 30px;
    z-index: 1;
    display: none !important
}

.slick-dots {
    bottom: -70px !important;
}

.slick-dots li {
    margin: 0 5px !important;
}

.slick-dots li button:before {
    font-size: 8px !important;
    color: #562ABD !important;
    opacity: 0.5 !important;
}

.slick-dots li.slick-active button:before {
    color: #210C6E !important;
    opacity: 1 !important;
}

.about-me-contact {
    text-decoration: none;
    color: black
}

.about-me-contact:hover {
    font-weight: 1000;
}

.collaborate-button {
    padding: 0.4em 0.7em;
    color: #562ABD;
    background-color: transparent;
    font-weight: 1000;
    font-size: 1.1em;
    border: 1.3px solid #562ABD;
    border-radius: 5px;
    transition: color 0.3s ease, background-color 0.3s ease;
}

@keyframes buttonHover {
    0% {
        color: #562ABD;
        background-color: transparent;
    }
    50% {
        color: #fff;
        background-color: #562ABD;
    }
    100% {
        color: #fff;
        background-color: #562ABD;
    }
}

.collaborate-button:hover {
    animation: buttonHover 0.6s ease forwards;
    transition-delay: 0.4s;
}

.resume-button {
    padding: 0.4em 0.7em;
    color: #fff;
    background-color: #562ABD;
    width: 100%;
    font-weight: 1000;
    font-size: 1.1em;
    border: 1.8px solid #562ABD;
    border-radius: 10px;
    transition: color 0.3s ease, background-color 0.3s ease;
    text-decoration: none;
}

.cv-button {
    padding: 0.4em 0.7em;
    color: #fff;
    background-color: #210C6E;
    width: 100%;
    font-weight: 1000;
    font-size: 1.1em;
    border: 1.8px solid #210C6E;
    border-radius: 10px;
    transition: color 0.3s ease, background-color 0.3s ease;
    text-decoration: none;
}

@keyframes buttonHover2 {
    0% {
        color: #fff;
        background-color: #562ABD;
    }
    50% {
        color: #562ABD;
        background-color: transparent;
    }
    100% {
        color: #562ABD;
        background-color: transparent;
    }
}

@keyframes buttonHover3 {
    0% {
        color: #fff;
        background-color: #210C6E;
    }
    50% {
        color: #210C6E;
        background-color: transparent;
    }
    100% {
        color: #210C6E;
        background-color: transparent;
    }
}

.resume-button:hover {
    animation: buttonHover2 0.6s ease forwards;
    transition-delay: 0.4s;
}

.cv-button:hover {
    animation: buttonHover3 0.6s ease forwards;
    transition-delay: 0.8s;
}

.about-and-others-div {
    font-family: Maven Pro;
    padding: 5em 4em 0
}

.about-and-others-div-1 {
    font-family: Maven Pro;
    padding: 2em 4em 5em;
}

.about-me-div {
    display: flex;
    gap: 7.5em
}

.phone-and-email-about-me {
    display: flex;
    gap: 3em;
    font-size: 1.1em;
    margin-top: 2em
}

.mobile-about-me-image {
    width: 15em;
    border-radius: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    margin: 3em auto;
}

.my-project-image {
    width: 45em;
    margin-right: 7em;
    border-radius: 40px
}

.my-project-title {
    font-weight: 600
}

.my-project-tag-div-1 {
    display: flex;
    gap: 0.5em;
    margin-top: 1.8em;
    font-size: 1em;
    opacity: 0.7
}

.my-project-description {
    margin-top: 1.5em
}

.my-project-div {
    background-color: #F4F3F6;
    font-family: Maven Pro;
    padding: 5em 0 4em 4em
}

.my-achievement-div {
    background-color: #562ABD;
    color: #fff;
    font-family: Maven Pro;
    padding: 6em 4em
}

.resume-cv-button-div {
    display: flex;
    gap: 2em;
    padding: 3em 2em 0;
    margin-top: 1.4em;
}

.entrance-main-div {
    display: flex;
    padding: 6em;
    min-height: 78vh;
    justify-content: center;
    align-items: center
}

.entrance-main-div-1 {
    width: 60%;
}

.entrance-main-div-11 {
    width: 78%;
}

.entrance-main-div-2 {
    width: 40%;
}

.entrance-main-text-font {
    font-weight: 1000;
    font-family: Maven Pro;
    font-size: 2.9em
}

.chatting-card {
    background-color: #562ABD;
    color: white;
    padding: 2em;
    border-radius: 30px
}

.chatting-card-div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: auto
}

.partnership-chat-card-heading {
    font-size: 1.8em;
    font-weight: 900
}

.partnership-chat-card-content {
    margin-top: 1.3em;
    color: #fff;
    font-size: 0.95em;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background-color: #6ED4A5;
    transform-origin: 0% 50%;
    z-index: 1000;
}

.map {
    width: 95%;
    display: flex;
    margin: 3em auto 0;
}

.translate-button {
    position: absolute;
    top: 120px;
    right: 20px;
    padding: 10px;
    background: #fff;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    display: flex;
    justify-content: end;
    align-items: end;
    text-align: end;
}

.ten-bio {
    padding: 4em 5em 4em;
    background-color: #490648;
    font-family: Maven Pro;
}

.download-bio-button {
    color: #000;
    text-decoration: none;
    padding: 0.7em 1em;
    background-color: #fff;
    border-radius: 10px
}

@media (max-width: 1000px) {
    .download-bio-button {
        font-size: 0.6em;
    }
    .ten-bio {
        padding: 2em 3em 4em;
    }
    .translate-button {
        top: 25px;
        right: 20px;
    }
    .progress-bar {
        display: none;
    }
    .chatting-card-div {
        width: 100%;
    }
    .entrance-main-div {
        display: inline;
    }
    .entrance-main-div-1 {
        width: 100%;
    }
    .entrance-main-div-11 {
        width: 90%;
        display: flex;
        margin: 3.5em auto;
        padding: 0 2em;
    }
    .entrance-main-div-2 {
        display: none;
    }
    .entrance-main-text-font {
        font-size: 2.6em;
    }
    .about-me-div {
        display: inline;
    }
    .phone-and-email-about-me {
        display: inline;
    }
    .about-and-others-div {
        padding: 5em 3em 0;
    }
    .my-project-image {
        width: 20em;
    }
    .my-project-title {
        font-size: 1em;
    }
    .my-project-tag-div-1 {
        font-size: 0.55em;
    }
    .my-project-year {
        font-size: 0.7em;
        opacity: 0.6;
    }
    .my-project-description {
        margin-top: 0.9em;
    }
    .collaborate-button {
        font-size: 0.95em;
    }
    .my-project-div {
        max-height: 50em;
    }
    .my-achievement-div {
        padding: 6em 3em;
    }
    .resume-cv-button-div {
        padding: 3em 0.5em 0em;
        gap: 2.5em;
        margin-top: 0.8em;
    }
    .carousel-card {
        font-size: 1.1em;
    }
    .carousel-track,
    .carousel-track-1 {
        gap: 90px;
    }
}

@media (max-width: 600px) {
    .my-project-image {
        width: 18em;
        margin-right: 3em;
        border-radius: 40px
    }
    .slick-dots {
        bottom: -70px !important;
    }
}