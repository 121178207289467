.background-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* Apply the brightness filter to the background image only */
    transition: opacity 3s ease-in-out;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 125%;
    /* Ensures the container takes up the full height of the background-image */
    text-align: center;
}

.Upcoming-button {
    padding: 0.7em 0.9em;
    border: 0;
    --tw-text-opacity: 1;
    background-color: #490648;
    font-size: 1.1em;
    font-weight: 600;
    color: #fff;
    border-radius: 10px;
}

.Reach-button {
    margin: 0 3em;
    background-color: transparent;
    border: transparent;
    font-size: 1.2em;
    font-weight: 600;
    color: #490648;
}

.Upcoming-button:hover {
    opacity: 0.7;
}

.Reach-button:hover {
    opacity: 0.7;
}

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 6em;
    color: #3d2101;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.ten {
    padding: 2em 5em 4em;
    background-color: #490648;
    font-family: Maven Pro;
}

.ten-h1 {
    font-size: 4em;
    font-weight: 700;
    text-transform: uppercase;
    color: #e8dddd;
    padding: 2.2em 0 0.7em;
}

.ten-h1:before {
    background-color: #bcb0b0;
    border-radius: 0.25rem;
    content: '';
    display: block;
    height: 0.25rem;
    width: 42px;
    margin-bottom: 1.25rem;
}

.intro-tagline {
    font-size: 4em;
    font-weight: 800;
    color: #f2eded;
    background-clip: text;
    -webkit-background-clip: text;
}

.content-one {
    font-size: 0.95em;
    font-weight: 600;
    color: #fff;
}

.background-containers {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #000;
    text-align: center;
    overflow: hidden;
}

.background-button-div {
    width: 100%;
    display: flex;
    padding: 0 8em;
}

.about-home-div {
    display: flex;
    margin-top: 5em;
    color: #fff;
}

.about-home-div-1 {
    width: 45%
}

.about-image-div {
    width: 26em;
    margin-top: 3.3em;
    border-radius: 12%
}

.about-home-div-2 {
    width: 55%;
    padding: 1.7em 0 0 1em
}

.achievement-home-div {
    width: 75%;
    margin: 0.7em 0
}

.ten-gallery-div {
    padding: 1em 4em 4em
}

@media (max-width: 900px) {
    .ten-gallery-div {
        padding: 1em 0 4em;
        display: flex;
        text-align: center;
        width: 100%;
    }
    .background-container {
        min-height: 140vh;
    }
    .Upcoming-button {
        font-size: 0.9em;
    }
    .background-button-div {
        padding: 2.3em 0 0 2em;
        margin: 80% auto 0;
        justify-content: center;
    }
    .Reach-button {
        font-size: 0.9em;
    }
    .ten-h1 {
        font-size: 2.5em;
        padding-bottom: 0.2em
    }
    .intro-tagline {
        font-size: 2.8em;
        text-align: center;
    }
    .about-home-div {
        display: inline;
        margin-top: 5em;
    }
    .about-home-div-1 {
        width: 100%;
    }
    .about-image-div {
        display: flex;
        width: 95%;
        margin: 2.5em auto;
        border-radius: 5%;
    }
    .about-home-div-2 {
        width: 100%;
        padding: 0.5em 0;
    }
    .ten {
        padding: 2em 3em 4em;
    }
    .achievement-home-div {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .Reach-button,
    .Upcoming-button {
        font-size: 0.8em;
    }
}

.image-container-for-album {
    height: 12em;
    width: 16em;
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin: 1em 0;
}

.image-container-for-album .hover-text-for-album {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    display: block;
}

.image-for-album {
    height: 12em;
    width: 16em
}

.image-for-album:hover {
    opacity: 0.8;
}


/*background-image: linear-gradient(to right, #bebab7, #f1f0ee, #ecddcf, #e4dbd0, #9c9083, #41270b);*/