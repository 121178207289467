.ten-h1-1 {
    font-size: 4em;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    padding: 2.2em 0 0.7em;
}

.ten-h1-1:before {
    background-color: #fff;
    border-radius: 0.25rem;
    content: '';
    display: block;
    height: 0.25rem;
    width: 42px;
    margin-bottom: 1.25rem;
}

.background-about-1 {
    width: 50%;
    background-color: #fff;
    padding: 0 0 5em
}

.background-about-2 {
    width: 50%
}

.background-about-3 {
    width: 100%;
    background-color: #F4DFCB;
    display: none;
}

.background-image-about {
    width: 100%;
    height: 100%
}

.about-main-div {
    margin: 0 9em
}

.about-div-text {
    color: #fff
}

@media (max-width: 900px) {
    .background-about-1 {
        display: none;
    }
    .background-about-2 {
        width: max-content;
    }
    .background-about-3 {
        display: flex;
        padding-top: 4em;
        padding-bottom: 0;
    }
    .background-image-about {
        height: 180%;
    }
    .about-main-div {
        margin: 0 2.5em;
    }
    .ten-h1-1 {
        font-size: 2.2em;
    }
    .about-div-text {
        font-size: 0.9em;
    }
}